import React, { useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { FaCloud, FaNetworkWired, FaShieldAlt } from "react-icons/fa";
import "./App.css";

const services = [
  { icon: <FaCloud size={40} className="text-blue-600" />, title: "Hostingové služby", description: "Hostingové služby podľa požiadaviek zákazníka." },
  { icon: <FaNetworkWired size={40} className="text-blue-600" />, title: "Počítačové siete", description: "Návrh a realizácia počítačových sietí." },
  { icon: <FaShieldAlt size={40} className="text-blue-600" />, title: "Počítačová bezpečnosť", description: "Konzultačná činnosť v oblasti počítačovej bezpečnosti." }
];


export default function Home() {

  useEffect(() => {
    document.title = "ELBOS, s.r.o.";
    }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <header className="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-8 text-center text-3xl font-bold shadow-md rounded-b-2xl">
        ELBOS, s.r.o.
      </header>
      
      <section className="text-center py-16">
        <h2 className="text-4xl font-bold text-gray-800">Naše služby</h2>
        <div className="grid md:grid-cols-3 gap-8 px-6 mt-10">
          {services.map((service, index) => (
            <Card key={index} className="p-8 shadow-xl rounded-2xl bg-white hover:shadow-2xl transition duration-300">
              <CardContent className="flex flex-col items-center">
                {service.icon}
                <h3 className="text-2xl font-semibold mt-4 text-gray-700">{service.title}</h3>
                <p className="text-gray-500 mt-2 text-lg">{service.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>
      
      <section className="text-center py-16 bg-gray-100 rounded-2xl mx-6">
        <h2 className="text-4xl font-bold text-gray-800">O nás</h2>
        <p className="text-gray-600 mt-6 max-w-3xl mx-auto text-lg">
          Sme IT spoločnosť poskytujúca hostingové služby (mail, web) a návrh webových stránok. Máme dlhoročné skúsenosti aj s návrhom počítačových sietí a počítačovej bezpečnosti.
        </p>
      </section>
      
      <section className="text-center py-16">
        <h2 className="text-4xl font-bold text-gray-800">Kontaktujte nás</h2>
        <p className="text-gray-600 mt-6 text-lg">
          ELBOS, s.r.o.<br />
          Pri Šajbách 3<br />
          831 06 Bratislava<br />
          Slovenská republika<br />
          IČO: 35884304<br />
          DIČ: 2021838907<br />
          Tel: +421-903-242018<br />
          <a href="mailto:mathes@elbos.sk">Email</a>
        </p>
      </section>
      
      <footer className="bg-gradient-to-r from-blue-500 to-blue-700 text-white text-center py-6 text-lg shadow-md rounded-t-2xl">
        &copy; 2012-2025 ELBOS, s.r.o. Všetky práva vyhradené.
      </footer>
    </div>
  );
}
